import styled from 'styled-components';
import { media } from '../../utils/style-utils';
import { GatsbyImage } from '../Styled';
import { withPrefix } from 'gatsby';

export const H1 = styled.h1`
  font-size: 1.6rem;
  font-weight: 900;
  margin: 1.2rem 0;
  font-family: 'Playfair Display', serif;

  ${media.phone`
    font-size: 1.8rem;
  `};

  ${media.tablet`
    font-size: 2.8rem;
  `};

  ${media.desktop`
    font-size: 3.6rem;
  `};

  ${media.hd`
    font-size: 4.2rem;
  `};
`;

export const H4 = styled.h4`
  font-size: 1.4em;
  font-weight: 900;
  margin: 1.2rem 0;
  font-family: 'Playfair Display', serif;

  ${media.phone`
    font-size: 1.6rem;
  `};

  ${media.desktop`
    font-size: 2.2rem;
  `};
`;

export const H6 = styled.h6`
  font-size: 1.2rem;
  font-weight: 900;
  margin: 2.6rem 0 0.8rem 0;
  font-family: 'Playfair Display', serif;
`;

export const Spacer = styled.div`
  margin-bottom: 4rem;
`;

export const MainImage = styled(GatsbyImage)`
  position: relative !important;
  width: 100%;
  height: 285px;

  ${media.tablet`
    height: 385px;
  `};

  ${media.desktop`
    height: 585px;
  `};
`;

export const PositionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 85%;
  margin: 48px auto 0 auto;
  overflow-x: hidden;

  ${media.tablet`
    max-width: 70%;
  `};

  ${media.desktop`
    max-width: 65%;
  `};

  ${media.hd`
    max-width: 55%;
  `};
`;

export const PositionFlexChild = styled.div`
  flex-basis: 100%;

  &:nth-child(1) {
    order: 1;
  }
  &:nth-child(2) {
    order: 3;
  }
  &:nth-child(3) {
    order: 2;
  }
  &:nth-child(4) {
    order: 4;
  }
  &:nth-child(5) {
    order: 6;
  }
  &:nth-child(6) {
    order: 5;
  }

  &:nth-child(7) {
    order: 7;
  }

  &:nth-child(8) {
    order: 8;
  }

  ${media.tablet`
    &:nth-child(1) { order: 1 };
    &:nth-child(2) { order: 2 };
    &:nth-child(3) { order: 3; margin-right: auto; };
    &:nth-child(4) { order: 4 };
    &:nth-child(5) { order: 5; margin-left: auto; };
    &:nth-child(6) { order: 6 };
    &:nth-child(7) { order: 7 };
    &:nth-child(8) { order: 8 };
  `};
`;

export const HeaderWrapper = styled.div`
  text-align: center;
  margin-top: 24px;

  ${media.tablet`
    margin-top: 24px;
  `};

  ${media.desktop`
    margin-top: 72px;
  `};
`;

export const LightHeader = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  margin: 16px auto 16px auto;
  flex-basis: 100%;

  ${media.tablet`
    font-size: 24px;
  `};

  ${media.desktop`
    font-size: 32px;
  `};
`;

export const Intro = styled.div`
  font-size: 1rem;
  padding: 0 4%;
  text-align: center;
  margin-bottom: 50px;
  margin-top: ${({ secondary }) => (secondary ? '0' : '24px')};
  font-weight: 300;

  ${media.tablet`
    font-size: ${({ secondary }) => (secondary ? '1.2rem' : '1.6rem')};
    margin-top: ${({ secondary }) => (secondary ? '0' : '48px')};
    padding: 0 10%;
  `};

  ${media.desktop`
    font-size: ${({ secondary }) => (secondary ? '1.4rem' : '1.8rem')};
    padding: ${({ secondary }) => (secondary ? '0 4%' : '0 8%')};
    margin-bottom: 4rem;
  `};

  ${media.hd`
    padding: 0 13%;
  `};
`;

export const Paragraph = styled.p`
  font-size: 14px;

  ${media.tablet`
    font-size: 18px;
  `};

  ${media.desktop`
    font-size: 20px;
  `};
`;

export const List = styled.ul`
  justify-self: flex-start;
  font-size: 14px;
  list-style: disc inside;

  ${media.tablet`
    font-size: 18px;
  `};

  ${media.desktop`
    font-size: 20px;
  `};

  & > li {
    margin-bottom: 8px;
  }
`;

export const BgImage = styled(PositionFlexChild)`
  width: 100%;
  height: 175px;
  background-image: ${({ imgUrl }) => `url(${withPrefix(`./jobs/${imgUrl}`)})`};
  background-position: center top 20%;
  background-size: cover;
  opacity: 1;
  margin: 24px auto 36px;

  ${media.tablet`
    margin: 24px auto 36px;
    height: 220px;
    flex-basis: calc(33% - 6px);
  `};

  ${media.desktop`
    margin: 24px auto 36px;
    height: 320px;
    flex-basis: calc(33% - 6px);
  `};

  ${media.hd`
    height: 420px;
  `};
`;

export const ApplyWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 64px 0 36px 0;
`;

export const ContactWrapper = styled(PositionFlexChild)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 4rem auto 10rem;
`;
